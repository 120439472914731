import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const Hero = props => (
  <div className={`${props.className} hero hero-large z-0 bg-primary hero-home relative`} style={props.style}>
    {props.fluid ? 
    <BackgroundImage Tag="div" fluid={props.fluid} className="w-full h-full absolute pin z-10" style={{ position: 'initial', backgroundPosition: 'center center' }} /> : null }
    <div className={`${props.overlay} absolute pin`} />
    <div className="hero-body text-center relative z-20">
      <div className="container flex justify-center">
        <div className="w-full md:w-4/5 text-center">
          <h1 className="uppercase">{props.title}</h1>
          {props.subheading ? <p className="text-xl mt-4 text-white">{props.subheading}</p> : null}
        </div>
      </div>
    </div>
  </div>
);

export default Hero;
